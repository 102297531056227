/*---Helpful Methods---*/
@import '/mixins';

/*---Theme Settings---*/
/* The general settings that aren't fonts & colors. */
@import '/settings';
/* The color palette and what goes on each page */
@import '/theme-colors/smoothblue';
@import '/theme-designs/light-primary';
/* Font-families & sizes for all fonts */
@import '/fonts';
/* Reusable components- Buttons, Links, Dividers */
@import '/components';
/* CSS animations for pages & route changes, etc. */
@import '/animations';


/*---Page Components---*/
/*Styling for menu*/
@import '/menu';
@import '/lab';
/*Styling that is used on every page.*/
@import '/page';


/*---Individual Components---*/
@import '/components/homeLinks';

.color-box {
	margin: 20px auto;
	padding: 20px;
	background: linear-gradient(rgba(255,255,255,.6),rgba(255,255,255,.8),rgba(255,255,255,.4));
	border-radius: 20px;
	border: 1px outset #333;
	max-width: 800px;
}

* {
	box-sizing: border-box;
}
.body,
.controller {
	min-height: 100vh;
}

.App {
	text-align: center;
}

ul { list-style:none;padding:0;}

.form-group {margin-bottom:25px !important;text-align:left;}

.main-bg {
	background-color: $mainBgColor;
	
	background-size: cover;
	background-attachment: fixed;
	background-position:center;
}
.main-screen {
	$weights: "/images/widebackground.png";	
	background: linear-gradient(
		rgba($backgroundOpacityColor, .65) 10%,
		rgba($backgroundOpacityColor, .45) 20%,
		rgba($backgroundOpacityColor, .55) 40%,
		rgba($backgroundOpacityColor, .7) 70%,
		rgba($backgroundOpacityColor, .95) 100%
	), url($weights);
	background-position:right;
	background-size: cover;
	background-attachment: fixed;
}

.main-screen-persist-menu {
	width: 75vw; 
	margin-left: 25vw;
}

.staff-square {
	background-color:$ll-secondary-color;
	border:1px solid $d-secondary-color;
	border-radius:4px;
	padding:10px;
}

.main-page {
	background-color: rgba($secondary-color, .7);
}

.body {
	$techbg: "/images/techbg.jpg";	
	background-color: $l-primary-color;
	background: linear-gradient(rgba($l-primary-color, .8), rgba($primary-color, .8)), url($techbg);
	background-size: cover;
	background-attachment: fixed;
}

.page-field {
	margin: 5px;
	padding: 5px;
	border-radius: 5px;
	max-width: 500px;
	width: 100%;
	color: $dark-color;
}

.pageDarkSection {
	text-align: center;
	background: $d-highlight-color;
	padding: 40px 20px 80px;
}

.footer {
	background-color: $footerBackgroundColor;
	padding: 40px;
	border-top:12px solid $footerAccentColor;
	border-bottom:12px solid $footerAccentColor;
}

.modal-title {
	color: $light-color;
}
.modal-body {
	color: $l-light-color;
}


.extra-info-slider {
	background-color: rgba(white, .2);
	padding: 30px;
	display:block;
	overflow:scroll;
	min-width:100%;
}

.extra-info-row {
	width: max-content;
}

.extra-info-column {
	cursor: pointer;
	min-width: 50px;
	width: max-content;
}

.eic-active {
	text-decoration: underline;
	color: $ll-light-color;
}

@media only screen and (max-width: 911px) {
	.extra-info-slider,
	.image-slider {
		overflow-y: scroll;
	}
}

.handlerForm {
	max-width: 800px;
}



.inlineForm {
	.formField {
		display: inline-block;
		padding: 5px;

		input {
			width: 150px;
		}
	}

	.formHeader {
		display: none;
	}

	.handlerForm {
		max-width: 90vw;
	}
}

.loader {
	display: block;
	text-align: center;
	position: relative;
}

.loaderImg {
	background-color: $light-color;
	border-radius: 50%;
	position: absolute;
	margin-left: -30px;
	opacity: .8;
	transition: opacity 500ms;
}

.amazon-link {
	img {
		margin: 10px auto;
		width: 80%;
		border-radius: 20px;
		border: 4px inset $light-color;
	}
}

.shop-category {
	border-top: 2px solid $light-color;
	border-bottom: 2px solid $light-color;
	padding: 10px 0;
	background-color: rgba($light-color, .4);
}

.connection-row {
	background-color:$dark-color;
	width:100vw;
	margin:0;
	& .col-12 {
		padding:0;
		border-left: 2px solid $d-grey-color;
		border-right: 2px solid $d-grey-color;
	}

}

.symbol-connection-area {

	background-color:$dark-color;
	& h3 {
		background-color:$l-grey-color;
		padding:10px;
		color:$ll-light-color;
		margin:0;
	}
	& h4 {
		background:$d-grey-color;
		border-top:4px solid $grey-color;
		border-bottom:4px solid $dd-grey-color;
		margin:0;
		& a {
			font-size:inherit;
			background:none;
			width:80%;
			color: $light-color;
		}
	}
	& > span > a {
		margin: 20px 10px;
	}
}

.displayImage {
	padding:20px;
	background-color:$ll-primary-color;
	h2 { color: $dd-primary-color;}
	h3 { color: $d-primary-color;}
	& .displayImageHolder {
		border:8px inset $l-grey-color;
		width: min-content;
		height: min-content;
		margin:auto;
	}
	@media only screen and (max-width: 911px) {
		& .displayImageHolder {
			overflow:scroll;
			max-width: 80vw;
			max-height:200px;
			margin:auto;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none;  /* Internet Explorer 10+ */
			&::-webkit-scrollbar { /* WebKit */
				width: 0;
				height: 0;
			}
		}
	}
}

.DDcontainer {
	display:flex;
	flex-wrap:wrap;
	height:100%;
	width:100%;
}


.search-helper-box {
	border: 2px solid black;
	padding:5px;
	margin:5px;
}

.user-list-card {
	background:$l-secondary-color;
	padding:10px;
	border-top:4px solid $l-dark-color;
	border-bottom: 4px solid $primary-color;
}

.faq-questions {
	background:$colorSectionBackground;
	h3 {
		background-color:$colorSectionHeaderBackground;
		padding:20px;
	}
	.faq-answer{
		padding:20px;
		padding-top:0;
	}
}

.message {
	padding:20px;
	width:100%;
	margin: 10px 0;
	.message-date {
		color: $l-grey-color;
	}
}

#messages-scroll-box {
	background-color:grey;
	max-height:50vh;
	overflow:scroll;
	&>div {
	display:flex;
	flex-direction: row-reverse;
	flex-wrap: wrap-reverse;
	}
}