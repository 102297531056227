



.header {
	box-sizing: border-box;
	position: fixed;
	width: 25vw;
	height:100vh;
	z-index: 10;
	padding-left:10px;
	overflow-y: scroll;
	border-right:3px solid $menuAccentColor;
	box-shadow: 2px 0 8px black;
	top: 0;
	background: linear-gradient(lighten($headerMenuColor, 8%), darken($headerMenuColor, 8%));
	transition: background-color 100ms linear;
	&:hover {
		background-color: $headerMenuColor;
		transition: background-color 100ms linear;
	}



	.menu-right {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-wrap: wrap;
	}

	h3 {
		margin: 0px;
		font-family: $topFont;
	}
	a {
		color: $menuLinkColor !important;
		font-family: $headerFont;
	}
}

.mobile-menu-show > .hmenu-item:first-child {
	
	border-top: 2px solid $menuAccentColor;
	
}
.hmenu-item {

	border-bottom: 2px solid $menuAccentColor;
	margin:0;
	width:100%;
	display:inline-block;
	&:hover {
		background-color: rgba($menuHoverColor, .7);
	}
	& a, & * {
		color:$menuLinkColor;
	}
}

.hmenu-dropdown-toggle {
	display: inline-block;
	cursor: pointer;
	padding:5px;
}
.hmenu-dropdown-options {
	width:100%;
	background-color: $headerMenuColor;
	a {
		display: block;
	}
}


.headerFill {
	background-color: $headerMenuColor;
}


	.hmenu-dropdown-options .hmenu-item, .hmenu-item:not(.hmenu-dropdown) {
		display: flex;
		justify-content: space-between;
		& > span {
			padding: 5px;
		}
	}

	.hmenu-dropdown-toggle > span {
		padding: 6px;
	}
	
	.hide-menu {
		display:none;
	}

	.hmenu-mobile-toggle {
		position:fixed;
		margin-left:-20px;
	}