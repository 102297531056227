/* ------------------------*/
/* Buttons ----------------*/
/* ------------------------*/
button {
	color: $l-light-color;
	padding: 10px;
	border: 8px solid $l-grey-color;
	background: linear-gradient(rgba($l-secondary-color, .2), rgba($d-secondary-color, .2));
	font-family: $headerFont;
	margin: 2px;
	border-radius: 10px;
}

/* Nice links that are displayed as buttons */
.nice-button {
	display: inline-block;
	color: $defaultNiceButtonTextColor;
	padding: 10px;
	line-height:1.2;
	border: $defaultNiceButtonBorder;
	font-family: $headerFont;
	background: linear-gradient(rgba($defaultNiceButtonColor, .8), rgba($defaultNiceButtonColor, .8));
	@media only screen and (max-width: 911px) {
		width: 90%;
		margin: 10px auto;
	}
	@media only screen and (min-width: 912px) {
		width: 350px;
		max-width:90%;
		margin: 10px auto;
	}
}

.nice-button {
	&:hover {
		color:$defaultNiceButtonHighlightTextColor;
	}
}

.nice-button-primary {
	background: linear-gradient(rgba($ll-primary-color, .4), rgba($primary-color, .4));
	border: 8px solid $l-primary-color;
}

/* Sort/toggle buttons- have an active and inactive state. Add/remove .page-button-active to toggle. Usually, actually divs.*/
.page-button {
	margin: 5px;
	background-color: $secondary-color;
	font-family: $textFont;
	padding: 10px;
	border-radius: 5px;
	color: $ll-light-color;
	border: 2px solid $l-secondary-color;
	display: inline-block;
	cursor: pointer;
	&:hover {
		background-color: $l-secondary-color;
		border: 2px solid $d-secondary-color;
	}
}

.page-button-active {
	background-color: $d-secondary-color;
}

/* ------------------------*/
/* LINKS ------------------*/
/* ------------------------*/
a, .format-link {
	cursor: pointer;
	color: $linkColor;
	text-decoration: none;
	padding: .5vw;
	margin: 2%;
	border-radius: $borderRadius;
	display: inline-block;
	background: linear-gradient(rgba($linkBackground, .1),  rgba($linkBackground, .5));
	&:hover {
		background: rgba($linkHoverBackground, .7);
		color: $linkHoverColor;
	}
}

.blockLink {
	display: block;
	padding: 15px;
	margin: 20px 0;
	&:hover {
		text-decoration: none;
		background-color: rgba($light-color, .2);
	}
}

.paginationLinks {
	text-align: center;
	font-size: 18px;
	& > span {
		padding: 5px;
		font-family: $topFont;
		&:hover {
			cursor: pointer;
			font-weight: bolder;
		}
	}
}

/* ------------------------*/
/* Dividers ------------------*/
/* ------------------------*/
hr {
	background-color: grey;
}

.divider {
	box-sizing: border-box;
	height: 12px;
	background-color: $dividerColor;
	border-top: 3px solid $l-dividerColor;
	border-bottom: 3px solid $d-dividerColor;
}

.reverse-divider {
	box-sizing: border-box;
	height: 12px;
	background-color: $dividerColor;
	border-bottom: 3px solid $l-dividerColor;
	border-top: 3px solid $d-dividerColor;
}

/* ------------------------*/
/* Warnings ------------------*/
/* ------------------------*/
.health-warning {
	background: rgba($l-highlight-color, .4);
	padding: 20px;
	color: $light-color;
	border: 5px solid $l-highlight-color;
}

/* ------------------------*/
/* Images ------------------*/
/* ------------------------*/
img {
	border-radius: 4px;
}
.image-gallery {
	padding:10px 0;
	& img {
		padding: 10px;
	}
}

.mini-form-handler {
	padding-bottom:20px;
	background-color:$l-dark-color;
	border-bottom:4px solid $light-color;
	h5 {
		background-color:$grey-color;
		color:$dark-color;
		padding:10px;
		border-bottom:2px solid $dark-color;
	}
}

.full-form, .mini-form {
	max-width:800px;
	margin:auto;
	padding:20px;
}

select {
	
	border-radius:16px;
	background-color:lighten($light-color, 8%);
	border: none;
	border-bottom: 5px outset $d-primary-color;
	border-top: 1px outset $d-primary-color;
	color: $d-dark-color;
	padding:10px 20px;
	margin:3px;
	font-size:1.2em;
	cursor:pointer;
}

input, input.form-control, textarea.form-control{
	border-radius:16px;
	background-color:lighten($light-color, 8%);
	border: none;
	border-bottom: 5px outset $d-primary-color;
	border-top: 1px outset $d-primary-color;
	color: $dark-color;
	padding:10px 20px;
	margin:3px;
	font-size:1.2em;
}
input, input.form-control:focus, textarea.form-control:focus{
	background-color:$l-light-color;
	border: 3px inset $primary-color;
}

input#musicRange, input#effectRange {
	padding: 0px !important;
}

.slider {
	-webkit-appearance: none;
	width: 100%;
	height: 15px;
	border-radius: 5px;   
	background: #d3d3d3;
	outline: none;
	opacity: 0.7;
	-webkit-transition: .2s;
	transition: opacity .2s;
  }
  
  .slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	border-radius: 50%; 
	background: #0b3355;
	cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: #4CAF50;
	cursor: pointer;
  }
  /* Mouse-over effects */
  .slider:hover {
	opacity: 1; /* Fully shown on mouse-over */
  }