#lab-layout-light-switch {
    box-shadow: 0px 0px 8px white;
    border-radius: 8px;
    position: absolute; 
    z-index: 99999;

    width: 8%;
    height: 12%;

    right: 10px;
    top: 40%;

    /* set background image in component */
    background-position: center center;
    background-size: contain; 
    background-repeat: 'none none'
    
}

#lab-layout-light-transparency {
    position: absolute;
    z-index: 9999;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, .9)
}

#lab-layout-safety-gear {
    position: absolute; 
    right: 1%;
    width: 8%;
    z-index: 999; 

    span {
        text-align: center; 
        cursor: pointer; 
        height: 80px;
        width: 100%;
    }

    #lab-layout-coat-image {
        width: 100%; 
        right: 0; 
    }

    #lab-layout-goggle-image {
        width: 50%; 
        height: auto; 
        right: 0; 
    }
}