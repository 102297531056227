$ll-primary-color: #cedbe7;
$l-primary-color: #7fa2c0;
$primary-color: #426787;
$d-primary-color: #1b2b38;
$dd-primary-color: #000000;
$ll-secondary-color: #f3e3e2;
$l-secondary-color: #d0948f;
$secondary-color: #a44d45;
$d-secondary-color: #512622;
$dd-secondary-color: #000000;
$ll-highlight-color: #b69dae;
$l-highlight-color: #7f5e74;
$highlight-color: #3b2c36;
$d-highlight-color: #000000;
$dd-highlight-color: #000000;
$ll-light-color: #ffffff;
$l-light-color: #ffffff;
$light-color: #d0dde8;
$d-light-color: #81a4c1;
$dd-light-color: #436989;
$ll-grey-color: #dadada;
$l-grey-color: #9f9f9f;
$grey-color: #646464;
$d-grey-color: #292929;
$dd-grey-color: #000000;
$ll-dark-color: #767676;
$l-dark-color: #3b3b3b;
$dark-color: #000000;
$d-dark-color: #000000;
$dd-dark-color: #000000;
