$textFont: 'Open Sans', serif;
$headerFont: 'Signika';
$topFont: 'Bowlby One SC', cursive;


html, body { font-size: 1vw; }

@media only screen and (min-width: 1400px) {
	html, body { font-size: .75vw; }
}

p,
a,
* {
	font-size: 1em;
	font-family: $textFont;
	color: $textFontColor;
}

@mixin h-tag($color: $headerFontColor, $font: $headerFont) {
	font-family: $font;
	color: $color;
	margin: 5px 0;
}

h1 {
	@include h-tag($font: $topFont, $color: $topFontColor);
	@include text-shadow($ll-highlight-color, 1px, -1px, 0px);
	font-weight: bolder;
	font-size: 2em;
	
}

h2 {
	@include h-tag;
	font-size: 1.6em;
}
h3 {
	@include h-tag;
	font-size: 1.4em;
}
h4 {
	@include h-tag($font: $textFont);
	font-size: 1.2em;
}
h5 {
	@include h-tag($font: $textFont);
	font-size: 1.1em;
	text-decoration: underline;
}
