$contrast: 40%;
$metal: '/images/texture_metalplate.png';

$displayBox: rgba(130, 170, 172, .8);
$displayBorder: 1% inset darken($displayBox, $contrast/2);

.display {
	padding: 1%;
	border-radius: 1%;
}

@mixin text-shadow($color: black) {
	text-shadow: 0 0 .2vw $color;
}

#labScreen {
	width: 75vw;
	position: relative;
	height: 44.64vw;
	background-color: $l-primary-color;
	background-size: 100% 100%;
	margin: auto;
	* {
		font-size: 1em;
	}
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.hold {
	cursor: grabbing;
}

#topPart {
	height: 54%;
	padding: 1%;
	position: relative;
	& > div {
		display: flex;
		align-items: space-between;
		justify-content: space-between;
	}
}
#midPart {
	height: 26%;
	padding: 3%;
}
#bottomPart {
	height: 20%;
}

#shelveSection {
	width: 100%;
	height: 100%;
	padding: 0 0 1.5% 1%;

	& > div {
		width: 31%;
		margin: 0 1.75% 0 0%;
		height: 100%;
		text-align:left;
		float:left;
	}
}

.shelf {
	width: 100%;
	height: 25% !important;
	position: relative;
	display:flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background: linear-gradient(transparent, rgba(white, .6), transparent);
		@include text-shadow($d-primary-color);
	}
}

.open-shelf {
	.shelf-contents {
		font-weight:500;
		background-color: $primary-color;
		height:auto;
		max-height: 65vh;
		bottom: 100%;
		overflow: hidden scroll;
		opacity:1;
		transition: all 500ms ease-in-out;
	}
}

.close-shelf {

	.shelf-contents {
		height:0;
		max-height:0;
		opacity:0;
		bottom: -100%;
		height:max-content;
		transition: bottom 500ms ease-in-out, opacity 500ms ease-in-out;
		overflow:hidden;

	}
}

.item-weight,
.item-volume,
.item-description,
.item-properties,
.item-contents,
.item-used {
	display: none;
}

.item-name {
	img {
		width: 100%;
		max-width: 3.4vw;
	}
}

#examiner {
	background-color: rgba(94, 161, 169, .8);
	text-align: center;
	border: $displayBorder;
	.item-weight,
	.item-volume,
	.item-description,
	.item-properties,
	.item-contents,
	.item-used {
		display: block;
	}
	border-radius: 2%;
	max-height: 100%;

	bottom: 10px;

	position: absolute;
	overflow-y: scroll;
	overflow-x: visible;
}

#wholeTable {
	width: 100%;
	height: 100%;
}

$tableColor: rgba(19, 17, 15, .65);

#tableTop {
	height: 100%;
	display: flex;
	flex-wrap: wrap;
}

#tableLeft {
	width: 75.25%;
	height: 100%;
}

#tableSink {
	width: 15.25%;
}

#drawersCom {
	z-index: 1;
	padding: 1%;
	height: 18%;
	display: flex;
}

.drawer {
	width: 48%;
	height: 100%;
	margin: 1%;
}

$dropColor: rgba(180, 180, 180, .3);

.dropzone:empty {
	border: 3% dashed #bbd;
	color: grey;
}

.block-number-text {
	color: $primary-color;
	font-weight: bold;
	font-size: 1.2em !important;
}

.dropzoneempty {
	background-color: $dropColor;

}

p.dropzoneempty {
	height: 100%;
	color: $primary-color;
	font-weight: bold;
	font-size: 1.2em !important;
}

.drop-section {
	width: 23%;
	margin: 1%;
}
.drop-column {
	width: 12%;
	margin: 0 1%;
}

.drop-sink {
	width: 100%;
	height: 100%;
	.item-options {
		display: none;
	}
	.dropzone {
		border-color: transparent;
	}
}

.sink-controls {
	display:flex;
	justify-content: space-evenly;
}

.drop-row {
	width: 98%;
	margin: 1%;
	background-color: $dropColor;
}

.waterButton {
	cursor: pointer;
	color: white;
	border-radius: 10%;
}

.waterHot {
	background-color: rgba(#d22, .3);
	&:hover {
		background-color: rgba(#d22, .6);
	}
	&:active {
		border-color: rgba(#f99, .8);
		background-color: rgba(#d22, 1);
	}
}

.waterCold {
	background-color: rgba(#22d, .3);
	&:hover {
		background-color: rgba(#22d, .6);
	}
	&:active {
		border-color: rgba(#99f, .8);
		background-color: rgba(#22d, 1);
	}
}

.drag-item {
	border: 2% solid black;
	border-radius: 4%;
	height: 100%;

	justify-content: center;
	cursor: grab;
}
.drag-item:hover {
	background-color: rgba(255, 255, 255, .4);
}

.tool-item > .drag-item {
	display: flex;
	border: 2% solid #222;
	height: 100%;
	max-height: 100%;
	align-items: center;
	justify-content: center;
	cursor: grab;
}

.drag-item:active,
.hold-drag {
	cursor: grabbing !important;
}

.hover {
	background-color: green;
}

.invisible {
	display: none;
}
.inventory-item > p {
	font-weight:600;
}
.inventory-item {
	background: linear-gradient($primary-color, darken($primary-color, 10%));
	background: linear-gradient(darken(#7b8e99, 15%), darken(#7b8e99, 30%));
	@include text-shadow($d-primary-color);
	
}

// .inventory-item, .inventory-item>p {
// 	display:inline-block;
// 	width:min-content;
// }

.shelf #drawerName {
	opacity: .5;
}
.shelf:hover #drawerName {
	opacity: .8;
}
.inventory-item:hover {
	
	@include text-shadow($d-primary-color);
	background: linear-gradient( transparent, rgba(180, 180, 180, .3), transparent), darken($primary-color, 10%);
	
	& > p { color:white !important; }
}

.lab-action {
	position: relative;
}

.lab-action .lab-hover-popup {
	display: none;
}

.lab-action:hover .lab-hover-popup {
	display: block;
}

.lab-action .lab-hover-popup-op {
	position: absolute;
	opacity: .3;
	width: 100%;
	top:0;
	@include text-shadow(white);
	& span {
		text-shadow: none;
	}
}


.format-link.lab-action:not(.remove-item) {
    display: none;
}

#examiner .format-link.lab-action {
    display: inline-block;
}

.lab-action:hover {
	.lab-hover-popup-op {
		opacity: 1;
		background-color: transparent;
    }
}

.lab-action .lab-hover-popup-op.item-show-name {
	top: auto;
	bottom: 0;
}


.lab-action:hover > span {
	display: inline-block;
}

.lab-action:active > span {
	display: none;
}

.item-options {
	display: none;
	top: 100%;
	min-width: 100%;
	margin: auto;
	color: $ll-primary-color;
}
.item-options:hover {
	display: inline-block;
}

.lab-action:hover .item-options,
#examiner .item-options {
	display: inline-block;
}

.lab-action > span {
	display: none;
	position: absolute;
	background-color: $d-primary-color;
	color: $ll-primary-color;
}

.item-options .lab-action > span {
	
	z-index: 1000;
	overflow: visible;
	height:min-content;
	padding: 10%;
	border-radius: 1%;
	bottom:-80%;
	left: -50%;
	width:max-content;
}

.content-display {
	position: absolute;
	background-color: $d-primary-color;
	color: $ll-primary-color;
	right: 0;
}

.ttip {
	position: absolute;
	background-color: #37676D;
	color: $ll-primary-color;
	bottom: 0;
	right: 0;
	display: none;
}

.ttiphover:hover > .ttip {
	display: block;
}

.ttiphover > h1 {
	background-color: #37676D;
	display: inline-block;
	font-size: 2em;

	border-radius: 1%;
}

.ttipalt > h1 {
	display: inline-block;
	font-family: $textFont;
	font-size:100% !important;
	padding:10%;
	height:100%;
	width:100%;
}

.ttipalt:hover > .ttip {
	display: block;
	width: 100%;
}

#gameMessage {
	position: absolute;
	display: block;
	bottom: 10vh;
	width: 80%;
	
	margin: 0 10%;

	text-align: center;
	z-index: 100;
	background-color: $l-primary-color;

}

#gameMessage .fas {
	border-radius: 50%;
	padding: 1%;
	cursor: pointer;
}

.admin-bar {
	background-color: $ll-primary-color;
}

.experimentListName {
	background: linear-gradient(rgba(255, 255, 255, .1), transparent, rgba(0, 0, 0, .1));
}

.experimentListName:hover {
	background: linear-gradient( rgba(white, .2),  rgba(0, 0, 0, .2), rgba(white, .2));
}